/**
 * @description
 * This service provides all common data fetching operations
 * Do not use for route specific fetching - e.g. product catalogue should fetch via it's own data service
 * to scale the file size of the JS
 */
import {Injectable} from "@angular/core";
import {NavigationEnd, Router, UrlTree} from "@angular/router";
import {filter} from "rxjs/operators";
import {MenuService} from "../modules/menu/menu.service";

@Injectable()
export class DataService {

    get iconForRoute(): string {
        return this._iconForRoute;
    }

    private _iconForRoute: string;

    private _dataLoading: number = 0;
    get dataLoading(): boolean {
        return this._dataLoading > 0;
    }

    set dataLoading(value: boolean) {
        if (value) {
            this._dataLoading++;
        } else {
            this._dataLoading--;
        }
        if (this._dataLoading < 0) {
            this._dataLoading = 0;
        }
    }

    constructor(private router: Router, private mnuSvc: MenuService) {
        this.router.events
            .pipe(filter(f => f instanceof NavigationEnd))
            .subscribe((res: NavigationEnd) => {
                const tree: UrlTree = this.router.parseUrl(res.url);
                let menuItem;
                if (tree.root.children.primary) {
                    const firstSegment:string = tree.root.children.primary.segments[0].path;
                    menuItem = this.mnuSvc.flatMenuItems.filter(f => f.mainRoute === firstSegment)[0];
                } else {
                    menuItem = this.mnuSvc.flatMenuItems.filter(f => f.mainRoute === '')[0];
                }

                this._iconForRoute = menuItem ? menuItem.iconClass : '';
            })
    }

}
