import {EventEmitter, Injectable, OnDestroy} from "@angular/core";
import {DigestService} from "./digest.service";
import {AuthInfo, IEmitterMessage} from "../interfaces/general";
import {deleteFromSession, loadFromSession, saveToSession} from "../helpers/cookie.helper";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {CredentialStorage} from "./credential-storage.service";

declare const moment: any;

@Injectable()
export class TimerService implements OnDestroy {

    timerTick: EventEmitter<string>;
    private handle: any;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(private digestSvc: DigestService) {

        this.timerTick = new EventEmitter<string>();

        this.digestSvc.loginStatus
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((r: IEmitterMessage) => {
                if (r.data['Success']) {
                    this.startTimer();
                    saveToSession('tim', true);
                }
            });

        digestSvc.userSignedOut
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                clearInterval(this.handle);
                deleteFromSession('tim');
            });

        let tim = <boolean>loadFromSession('tim');
        if (tim) this.startTimer();
    }

    private startTimer(): void {
        this.handle = setInterval(() => {this.onTimer()}, 1000);
    }

    onTimer(): void {
        let mt = moment();
        let ai: AuthInfo = CredentialStorage.authInfo;

        let mtValidTo = moment(ai.validTo);
        let subs = moment.duration(mtValidTo.diff(mt));
        let timeStr = `${subs.hours()}:${subs.minutes()}:${subs.seconds()}`;

        this.timerTick.emit(timeStr);

        if (mt > mtValidTo) {
            clearInterval(this.handle);
            deleteFromSession('tim');
            this.digestSvc.logOut();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}