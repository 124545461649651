/**
 * Main application module
 * WARNING:
 * Since this module is loaded first, keep it as small as possible!!!
 * do not add any route-specific features here, look into "app/modules/app/app.routes.ts"
 * how this should be done correctly
 */
import {NgModule, enableProdMode, APP_INITIALIZER, LOCALE_ID, ErrorHandler} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent}   from './app.component';
import {routing, appRoutingProviders} from "./app.routes";
import {DataService} from "../../services/data.service";
import {DigestService} from "../../services/digest.service";
import {ErrorService} from "../../services/error.service";
import {GetLocaleFactory, SettingsService} from "../../services/settings.service";
import {LoginModule} from "../login/login.module";
import {SharedAppSettings} from "../../shared-settings/shared-settings";
import {MenubarModule} from 'primeng/primeng';
import {GlobalErrorHandler} from "../../services/global-error-handler.service";
import {MessageService} from "../../services/message.service";
import {TimerService} from "../../services/timer.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MainInterceptor} from "../../interceptors/main.interceptor";
import {IntegrationService} from "../../interceptors/integration.service";
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from "@angular/common";
import {CredentialStorage} from "../../services/credential-storage.service";
import {MenuHelpersModule} from "../../directives/menu/menu-helpers.module";
import {MenuModule} from "../menu/menu.module";
import { PreloaderModule } from "../preloader/preloader.module";

registerLocaleData(localeCs, 'cs-CZ');

export function LangServiceFactory(seSvc: SettingsService) {
    return () => seSvc.getAllTranslations()
}

enableProdMode();

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        MenuHelpersModule,
        HttpClientModule,
        LoginModule,
        PreloaderModule,
        MenubarModule,
        MenuModule,
        routing

    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        CredentialStorage,
        Title,
        appRoutingProviders,
        DataService,
        DigestService,
        ErrorService,
        /**
         * @description
         * If you need your pipes to respond to culture changes
         * you must configure LOCALE_ID provider via settings {@link SettingsService} GetLocaleFactory function
         */
        {
            provide: LOCALE_ID,
            deps: [SettingsService],
            useFactory: GetLocaleFactory
        },
        {
            provide: APP_INITIALIZER,
            useFactory: LangServiceFactory,
            deps: [SettingsService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MainInterceptor,
            multi: true
        },
        IntegrationService,
        MessageService,
        SharedAppSettings,
        SettingsService,
        TimerService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {}
