import {Injectable, ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RouteGuardService} from "../../services/route-guard.service";
import {RouteTranslation} from "../../interfaces/general";

export const routes: Routes = [
    /**
     * OPEN ROUTES
     * these routes MUST NOT use RouteGuardService
     */
    {path: 'pa', loadChildren: 'app/modules/pre-auth/pre-auth.module#PreAuthModule'},
    {path: 'not-authorized', loadChildren: 'app/modules/warnings/warnings.module#WarningsModule'},
    /**
     * SECURED ROUTES - always use {@link RouteGuardService}
     */
    {path: '', data: { developerOnly: false, titleKey: 'Dashboard', iconClass: 'fas fa-tachometer-alt'}, loadChildren: 'app/modules/index/index.module#IndexModule', pathMatch: 'full', canActivate: [RouteGuardService]},
    {path: 'translations', data: { developerOnly: true, titleKey: 'Překlady', iconClass: 'fa fa-language', group: 'Developer'}, loadChildren: 'app/modules/translations/translations.module#TranslationModule', canActivate: [RouteGuardService]},
    {path: 'articles', data: { developerOnly: false, titleKey: 'Články', iconClass: 'far fa-newspaper', group: 'Marketing'}, loadChildren: 'app/modules/articles/articles.module#ArticlesModule', canActivate: [RouteGuardService]},
    {path: 'parameters', data: { developerOnly: false, titleKey: 'Parametry', iconClass: 'fas fa-sliders-h', group: 'Prodej' }, loadChildren: 'app/modules/parameters/parameters-administration.module#ParametersAdministrationModule', canActivate: [RouteGuardService]},
    {path: 'options', data: { developerOnly: false, titleKey: 'Systémová nastavení', iconClass: 'fas fa-wrench', group: 'Nastavení'}, loadChildren: 'app/modules/options/options.module#OptionsModule', canActivate: [RouteGuardService]},
    {path: 'banners', data: { developerOnly: false, titleKey: 'Bannery', iconClass: 'far fa-images', group: 'Marketing'}, loadChildren: 'app/modules/banners/banners.module#BannersModule', canActivate: [RouteGuardService]},
    {path: 'emails', data: { developerOnly: false, titleKey: 'Emaily', iconClass: 'far fa-envelope', group: 'Nastavení'}, loadChildren: 'app/modules/emails/emails.module#EmailsModule', canActivate: [RouteGuardService]},
    {path: 'forms', data: { developerOnly: false, titleKey: 'Formuláře', iconClass: 'far fa-envelope', group: 'Nastavení'}, loadChildren: 'app/modules/user-forms/user-forms.module#UserFormsModule', canActivate: [RouteGuardService]},
    //{path: 'producers', data: { developerOnly: false, titleKey: 'Výrobci', iconClass: 'fas fa-industry', group: 'Přehledy'},loadChildren: 'app/modules/producers/producer.module#ProducerModule', canActivate: [RouteGuardService]},
    {path: 'products', data: { developerOnly: false, titleKey: 'Produkty', iconClass: 'fa fa-shopping-basket', group: 'Prodej'}, loadChildren: 'app/modules/products/products.module#ProductsModule', canActivate: [RouteGuardService]},
    {path: 'import-products', loadChildren: 'app/modules/products/products.module#ProductsModule', canActivate: [RouteGuardService]},
    {path: 'newsletters', data: { developerOnly: false, titleKey: 'Newslettery', iconClass: 'far fa-file-alt', group: 'Marketing'}, loadChildren: 'app/modules/newsletters/newsletters.module#NewslettersModule', canActivate: [RouteGuardService]},
    {path: 'newsletter-mail-groups', data: { developerOnly: false, titleKey: 'Mailové skupiny', iconClass: 'far fa-address-book', group: 'Marketing'}, loadChildren: 'app/modules/newsletter-mail-groups/newsletter-mail-groups.module#NewsletterMailGroupsModule', canActivate: [RouteGuardService]},
    {path: 'categories', data: { developerOnly: false, titleKey: 'Kategorie', iconClass: 'fas fa-sitemap', group: 'Prodej'}, loadChildren: 'app/modules/categories/categories.module#CategoriesModule', canActivate: [RouteGuardService]},
    {path: 'orders', data: { developerOnly: false, titleKey: 'Objednávky', iconClass: 'fas fa-shopping-cart', group: 'Přehledy'}, loadChildren: 'app/modules/orders/orders.module#OrdersModule', canActivate: [RouteGuardService]},
    {path: 'error-pages', data: { developerOnly: false, titleKey: 'Chybové stránky', iconClass: 'fa fa-exclamation-triangle', group: 'Nastavení'}, loadChildren: 'app/modules/error-pages/error-pages.module#ErrorPagesModule', canActivate: [RouteGuardService]},
    {path: 'shipments', data: { developerOnly: false, titleKey: 'Doprava a platba', iconClass: 'fa fa-truck', group: 'Prodej' }, loadChildren: 'app/modules/shipment-payment/shipment-payment.module#ShipmentPaymentModule', canActivate: [RouteGuardService]},
    //{path: 'google-analytics', data: { developerOnly: false, titleKey: 'Google analytics', iconClass: 'fas fa-chart-line', group: 'Marketing'}, loadChildren: 'app/modules/google-analytics/google-analytics.module#GoogleAnalyticsModule', canActivate: [RouteGuardService]},
    {path: 'metrics', data: { developerOnly: false, titleKey: 'Měřící kódy', iconClass: 'fas fa-chart-line', group: 'Nastavení'}, loadChildren: 'app/modules/metrics/metrics.module#MetricsModule', canActivate: [RouteGuardService]},
    {path: 'reviews', data: { developerOnly: false, titleKey: 'Recenze', iconClass: 'fab fa-discourse', group: 'Marketing'}, loadChildren: 'app/modules/reviews/reviews.module#ReviewsModule', canActivate: [RouteGuardService]},
    {path: 'rights', data: { developerOnly: false, titleKey: 'Administrace práv', iconClass: 'fa fa-user-secret'}, loadChildren: 'app/modules/rights/rights.module#RightsModule', canActivate: [RouteGuardService]},
    {path: 'sitemap', data: { developerOnly: true, titleKey: 'Soubory Sitemap', iconClass: 'fas fa-map-signs', group: 'Developer'}, loadChildren: 'app/modules/sitemap/sitemap.module#SiteMapModule', canActivate: [RouteGuardService]},
    {path: 'customers', data: { developerOnly: false, titleKey: 'Zákazníci', iconClass: 'fa fa-shopping-bag', group: 'Přehledy'}, loadChildren: 'app/modules/customers/customers.module#CustomersModule', canActivate: [RouteGuardService]},
    {path: 'registrations-approvals', data: { developerOnly: false, titleKey: 'Schválení registrací', iconClass: 'fas fa-user-plus', group: 'Přehledy'}, loadChildren: 'app/modules/registrations-approvals/registrations-approvals.module#RegistrationsApprovalsModule', canActivate: [RouteGuardService]},
    {path: 'pricelists', data: { developerOnly: false, titleKey: 'Ceníky', iconClass: 'fas fa-dollar-sign', group: 'Prodej'}, loadChildren: 'app/modules/pricelists/pricelists.module#PriceListsModule', canActivate: [RouteGuardService]},
    {path: 'general-config', data: { developerOnly: false, titleKey: 'Obecná nastavení', iconClass: 'fas fa-cog', group: 'Nastavení'}, loadChildren: 'app/modules/general-config/general-config.module#GeneralConfigModule', canActivate: [RouteGuardService]},
    {path: 'feed-list', data: { developerOnly: false, titleKey: 'Feedy', iconClass: 'fa fa-rss', group: 'Přehledy'}, loadChildren: 'app/modules/feeds-simple/feeds-simple.module#FeedsSimpleModule', canActivate: [RouteGuardService]},
    {path: 'transports', data: { developerOnly: false, titleKey: 'Přenosy', iconClass: 'fas fa-database', group: 'Přehledy'},loadChildren: 'app/modules/transports/transports.module#TransportsModule', canActivate: [RouteGuardService]},
    {path: 'stylesheet', data: { developerOnly: true, titleKey: 'Stylesheet', iconClass: 'fab fa-html5', group: 'Developer'}, loadChildren: 'app/modules/stylesheet/style-sheet.module#StyleSheetModule'},
    {path: 'coupons', data: { developerOnly: false, titleKey: 'Slevové kupóny', iconClass: 'fas fa-piggy-bank', group: 'Slevový systém'},loadChildren: 'app/modules/coupons/coupons.module#CouponsModule', canActivate: [RouteGuardService]},
    //{path: 'gifts', data: { developerOnly: true, titleKey: 'Dárky', iconClass: 'fas fa-gift', group: 'Slevový systém'},loadChildren: 'app/modules/gifts/gifts.module#GiftsModule', canActivate: [RouteGuardService]},
    //{path: 'benefits', data: { developerOnly: false, titleKey: 'Benefity', iconClass: 'fas fa-newspaper', group: 'Nastavení'},loadChildren: 'app/modules/benefit/benefit.module#BenefitModule', canActivate: [RouteGuardService]},
    {path: 'contact', data: { developerOnly: false, titleKey: 'Kontakt', iconClass: 'fa fa-outdent', group: 'Nastavení'},loadChildren: 'app/modules/contact/contact.module#ComponentModule', canActivate: [RouteGuardService]},
    {path: 'contact-page', data: { developerOnly: false, titleKey: 'Kontaktní stránky', iconClass: 'fas fa-file-alt',group: 'Nastavení'},loadChildren: 'app/modules/contact-page/contact-page.module#ContactPageModule', canActivate: [RouteGuardService]},
    {path: '**', redirectTo: '/chyba/404'}
];

/*
 * every route in use - except for '', '/pa' and '/not-authorized' MUST BE TRANSLATED HERE
 */
@Injectable()
export class RouteTranslationService {

    _routeTranslations: RouteTranslation[];

    get routeTranslations(): RouteTranslation[] {
        return this._routeTranslations;
    }

    set routeTranslations(value: RouteTranslation[]) {
        this._routeTranslations = value;
    }
    constructor () {
        this.routeTranslations = [
            // todo: leave this one in place
            {key: 'all', value: 'Vše'},
            // todo: iterate over routes and fill from there
            // todo: check if developer only when showing in rights route list
            {key: '', value: 'Dashboard'},
            {key: 'translations', value: 'Překlady', developerOnly: true},
            {key: 'articles', value: 'Články'},
            {key: 'parameters', value: 'Parametry'},
            {key: 'options', value: 'Systémová nastavení'},
            {key: 'banners', value: 'Bannery'},
            {key: 'emails', value: 'Emaily'},
            {key: 'forms', value: 'Formuláře'},
            //{key: 'producers', value: 'Výrobci'},
            {key: 'products', value: 'Produkty'},
            {key: 'newsletters', value: 'Newslettery'},
            {key: 'newsletter-mail-groups', value: 'Mailové skupiny'},
            {key: 'categories', value: 'Kategorie'},
            {key: 'orders', value: 'Objednávky'},
            {key: 'transports', value: 'Přenosy'},
            {key: 'shipments', value: 'Doprava a platba'},
            {key: 'error-pages', value: 'Chybové stránky'},
            {key: 'google-analytics', value: 'Google analytics'},
            {key: 'reviews', value: 'Recenze'},
            {key: 'rights', value: 'Práva'},
            {key: 'sitemap', value: 'Sitemap.xml', developerOnly: true},
            {key: 'customers', value: 'Zákazníci'},
            {key: 'registrations-approvals', value: 'Schválení registrací'},
            //{key: 'coupons', value: 'Slevové kupóny'},
            {key: 'gifts', value: 'Dárky'},
            {key: 'general-config', value: 'Obecná nastavení'},
            {key: 'pricelists', value: 'Ceníky'},
            {key: 'feed-list', value: 'Feedy'},
            {key: 'contact', value: 'Kontakt'},
            {key: 'contact-page', value: 'Kontaktní stránky'}
        ]
    }
}

export const appRoutingProviders: any[] = [
    RouteGuardService,
    RouteTranslationService
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
