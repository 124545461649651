import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, EMPTY  } from "rxjs";
import {Injectable} from "@angular/core";
import {IntegrationService} from "./integration.service";
import {HttpRegionalSettings} from "../interfaces/general";
import {CredentialStorage} from "../services/credential-storage.service";

@Injectable()
export class MainInterceptor implements HttpInterceptor {

    private settings: HttpRegionalSettings;

    constructor(private iSvc: IntegrationService) {
        this.settings = this.iSvc.httpRegional;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!/api\/preauth/.test(req.url) && !/api\/lang/.test(req.url) && !this.settings.comAllowed) return EMPTY ;

        this.settings = this.iSvc.httpRegional;
        let newUrl;

        const authHeadersBody: string = CredentialStorage.getStoredAuthHeadersBody(req.urlWithParams, req.method);

        if (req.method === 'GET') {
            newUrl = /\?/.test(req.url)
                ? `${req.url}&cu=${this.settings.cultureId}&cy=${this.settings.currencyId}`
                : `${req.url}?cu=${this.settings.cultureId}&cy=${this.settings.currencyId}`;
        } else newUrl = req.url;

        let duplicate;
        // the headers can also be set in DigestService.login()
        // so we must check not to duplicate them
        const isAlreadySet: boolean = req.headers.get("Authorization") && req.headers.get("Authorization").length > 0;

        if (authHeadersBody && !isAlreadySet) {
            duplicate = req.clone({
                headers: req.headers
                    .append('F-CC', `cu:${this.settings.cultureId},cy:${this.settings.currencyId}`)
                    .append('Authorization', authHeadersBody),
                url: newUrl
            });
        } else {
            duplicate = req.clone({
                headers: req.headers
                    .append('F-CC', `cu:${this.settings.cultureId},cy:${this.settings.currencyId}`),
                url: newUrl
            });
        }
        return next.handle(duplicate);
    }
}