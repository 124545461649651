import {OnDestroy, OnInit} from "@angular/core";
import {DataService} from "../services/data.service";
import {SettingsService} from "../services/settings.service";
import {ICurrency} from "../modules/index/common";
import {CredentialStorage} from "../services/credential-storage.service";

/**
 * @description
 * A class for storing authorization data in localStorage
 * Can be instantiated via:
 * - new ai()
 * - ai.fromString()
 * */
export interface AuthInfo {
    realm: string;
    nonce: string;
    clientNonce: string;
    userName: string;
    loggedIn: boolean;
    validTo: string;
    developerMode: boolean;
    a: number;
    b: number;
    c: number;
    hu: string;
}

export interface IKeyValue {
    key: string;
    value: string;
}

export interface RouteTranslation extends IKeyValue {
    developerOnly?: boolean;
}

export interface SettingItem {
    Key: string;
    Value: any;
}

/**
 * @description
 * Interfaces for passing proper request values between function calls
 * Just to have one param instead of 3 or more
 */
export interface IRequest {
    url: string;
    method: string;
    data?: any;
}

/**
 * @description
 * To have some structure of the emitted object to count with
 * Possible values for "type" are:
 * 'info'
 * 'error'
 */
export interface IEmitterMessage {
    type: string;
    message: string;
    status?: number;
    data?: any;
}

/**
 * @description
 * Defines culture properties
 */
export interface ICulture {
    code: string;
    uiCode: string;
    name: string;
    cultureId: number;
    currencyCode: string;
}

export interface ICountry {
    Id: number;
    Name: string;
}

export interface IArticleRoot {
    rootName: string;
}

export interface IValidationPattern {
    name: string;
    pattern: any;
    cultureId: number;
    isDefault?: boolean
}

/**
 * @description
 * Structure of the application settings which are stored in app/services/settings.service.ts.
 * This enables usage of settings via dot convention both in ts and html files.
 * If you add a setting to the json file, please add it to this interface as well.
 */
export interface ISettings {
    allowPermanentLogin: boolean;
    availDigitInfo: string;
    dateFormat: string;
    loginCookieExpirationHours: number;
    cultures: Array<ICulture>;
    currencies: Array<ICurrency>;
    defaultCultureId: number;
    defaultCurrencyId: number;
    defaultCountryId: number;
    articleRoots: IArticleRoot[];
    validationPatterns: IValidationPattern[];
    imageServerPathPrefix: string;
    serverUrl: string;
    countries: ICountry[];
    newsletterBannerFotoCache: string;
    newsletterProductFotoCache: string;
    primeng: IPrimeNG;
}

/**
 * @description
 * Defines an interface which should be used by every Component which needs translations
 */
export interface ITranslatable extends OnInit, OnDestroy {
    sen: Object;
}

/**
 * Base class for all components which use translations
 * translationPrefix is used to distinguish translations for particular routes (not to take them all from db)
 */
export abstract class Translatable implements ITranslatable {

    sen: any;
    private _userLoggedIn: boolean;

    protected constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        this.sen = this.seSvc.sen;
        /**
         * @description
         * we don't need to unsubscribe since we're reloading
         * thus killing all the Angular application
         */
        seSvc.cultureChanged
            .subscribe(() => location.reload());
    }

    get userLoggedIn(): boolean {
        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            this._userLoggedIn = ai.loggedIn;
        }
        return this._userLoggedIn
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
    }

}

export interface ILoginResult {
    Success: boolean;
    CultureId: number;
    CurrencyId: number;
    DeveloperMode: boolean;
    AdminUserRights: IAdminUserRights;
    A: number;
    B: number;
    C: number;
    Hu: string;
}

export interface IDomain {
    Id: number;
    DisplayName: string;
    DomainUrl: string;
}





export interface PagedResponse<T> {
    total: number;
    data: T;
}

export interface IRight {
    rightToken: string;
    right: number;
}

export interface IAdminUserRights {
    userId: number;
    userName: string;
    password?: string;
    developer: boolean;
    lastLogon?: any;
    lastUpdate?: any;
    dealerId?: number;
    rights: IRight[];
}

export interface IPrimeNG {
    locales: any;
}

export interface IFileSelector {
    fileData: string;
    fileType: string;
    fileName: string;
}

/**
 * @description
 * used to interchange info between settings service and auth.interceptor
 * for setting HttpHeaders
 */
export interface HttpRegionalSettings {
    cultureId: number;
    currencyId: number;
    comAllowed: boolean;
}

export interface DataRequest {
    url: string;
    method: string;
    data?: any;
}

export interface AdminMenuItem {
    mainRoute: string;
    titleKey: string;
    developerOnly: boolean;
    group: string;
    iconClass: string;
}

export interface AdminMenuGroup {
    groupName: string;
    icon: string;
    subItems: AdminMenuItem[];
}

export interface NumberIndexedObject {
    [key: number]: any;
}