import {Injectable} from "@angular/core";
import {AdminMenuGroup, AdminMenuItem} from "../../interfaces/general";
import {Router} from "@angular/router";
import {DigestService} from "../../services/digest.service";

@Injectable()
export class MenuService {

    get flatMenuItems(): AdminMenuItem[] {
        return this._flatMenuItems;
    }
    private _flatMenuItems: AdminMenuItem[] = [];

    get menuItemsOrGroups(): Array<AdminMenuItem | AdminMenuGroup> {
        return this._menuItemsOrGroups;
    }
    private _menuItemsOrGroups: Array<AdminMenuItem | AdminMenuGroup> = [];

    get menuGroups(): AdminMenuGroup[] {
        return this._menuGroups;
    }

    set menuGroups(value: AdminMenuGroup[]) {
        this._menuGroups = value;
    }

    private _menuGroups: AdminMenuGroup[] = [];

    constructor(private router: Router, private digestSvc: DigestService) {
        if (this.digestSvc.userRights) this.processMenu();
    }

    private itemHasRight(itemRoute: string): boolean {
        let minRight: number = 0;
        if (itemRoute === 'rights') {
            minRight = 2;
        }
        const versatileRight = this.digestSvc.userRights.rights.filter(f => f.rightToken === 'all' && f.right > minRight).length > 0;
        const currentRight = this.digestSvc.userRights.rights.filter(f => f.rightToken === itemRoute && f.right > minRight).length > 0;
        return versatileRight || currentRight;
    }

    private itemSatisfiesDeveloperOnly(item: AdminMenuItem): boolean {
        if (!item.developerOnly) return true;
        else return this.digestSvc.developerMode;
    }

    private filterMenuGroupsToDisplay(inputGroups: AdminMenuGroup[]): AdminMenuGroup[] {
        for (let i = 0; i < inputGroups.length; i++) {
            inputGroups[i].subItems = inputGroups[i].subItems
                .filter(f => this.itemHasRight(f.mainRoute))
                .filter(f => this.itemSatisfiesDeveloperOnly(f));
        }
        return inputGroups;
    }

    private processMenu(): void {
        this._menuGroups.push(
            {
                groupName: 'Prodej',
                icon: 'fa fa-shopping-basket',
                subItems: []
            },
            {
                groupName: 'Slevový systém',
                icon: 'far fa-money-bill-alt',
                subItems: []
            },
            {
                groupName: 'Marketing',
                icon: 'fas fa-hand-holding-usd',
                subItems: []
            },
            {
                groupName: 'Developer',
                icon: 'fas fa-file-code',
                subItems: []
            },
            {
                groupName: 'Nastavení',
                icon: 'fas fa-cogs',
                subItems: []
            },
            {
                groupName: 'Přehledy',
                icon: 'fa fa-eye',
                subItems: []
            }
        );
        let tempItems: AdminMenuItem[] = [];
        this.router.config
            .filter(f => f.data && f.data['titleKey'])
            .forEach((elem) => {
                tempItems.push({
                    developerOnly: elem.data ? elem.data['developerOnly'] : false,
                    group: elem.data ? elem.data['group'] : '',
                    mainRoute: elem.path,
                    titleKey: elem.data ? elem.data['titleKey'] : '',
                    iconClass: elem.data ? elem.data['iconClass'] : '',
                });
            });

        this._flatMenuItems = tempItems
            .filter(f => this.itemHasRight(f.mainRoute))
            .filter(f => this.itemSatisfiesDeveloperOnly(f));

        const menuItems = tempItems
            .filter(f => !f.group || f.group === '')
            .filter(f => this.itemHasRight(f.mainRoute))
            .filter(f => this.itemSatisfiesDeveloperOnly(f));

        let menuGroups = this.menuGroups.map((elem) => {
            elem.subItems = tempItems.filter(f => f.group === elem.groupName);
            return elem;
        });
        menuGroups = this.filterMenuGroupsToDisplay(menuGroups)
            .filter(f => f.subItems.length > 0);

        this._menuItemsOrGroups = [...menuGroups, ...menuItems];
        // todo: sorting
    }

}
