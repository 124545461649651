import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MenuComponent} from "./menu.component";
import {MenuService} from "./menu.service";
import {RouterModule} from "@angular/router";
import {MenuHelpersModule} from "../../directives/menu/menu-helpers.module";

@NgModule({
    imports: [
        CommonModule,
        MenuHelpersModule,
        RouterModule
    ],
    declarations: [MenuComponent],
    exports: [MenuComponent],
    providers: [MenuService]
})

export class MenuModule {}