import {Component, Input, OnDestroy} from '@angular/core';
import {DigestService} from "../../services/digest.service";
import {AuthInfo, IEmitterMessage} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {CredentialStorage} from "../../services/credential-storage.service";

declare let sha256: any;

@Component({
    selector: 'cmp-login',
    templateUrl: '../../tpl/login.html',
})

export class LoginComponent implements OnDestroy {

    @Input('translations') sen: Object;

    displayUser: string;
    loggedIn: boolean = false;
    errorMessage: string;
    emailPattern: any;
    userForm: FormGroup;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public digestSvc: DigestService, private seSvc: SettingsService,
                private router: Router) {

        this.emailPattern = this.seSvc.settings.validationPatterns.filter(p => p.name == 'email')[0].pattern;

        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            this.loggedIn = ai.loggedIn;
            if (this.loggedIn) this.displayUser = ai.userName;
        }

        digestSvc.loginStatus
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((r: IEmitterMessage) => {
                if (r.data['Success']) {
                    this.displayUser = r.message;
                    this.errorMessage = undefined;
                    this.loggedIn = true;
                    this.router.navigateByUrl('/').then(() => {
                        if (r.data['CultureId'] != this.seSvc.defCulture.cultureId || r.data['CurrencyId'] != this.seSvc.defCurrencyId) {
                            location.reload();
                        }
                    });
                }
                else {
                    this.errorMessage = r.message
                }
            });

        /**
         * This is left here for security reasons, e.g. if the user tries to bz-pass the router secure mechanism
         */
        digestSvc.querySignOut
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.logOut()
            });
        digestSvc.userSignedOut
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.clearUserInfo()
            })
    }

    /**
     * @description
     * Calls {@link DigestService} to log the user in. Within this procedure Digest authentication scheme is being
     * carried out.
     */
    login(): void {
        this.digestSvc.login(this.userForm.get('userName').value, sha256(this.userForm.get('password').value))
    }

    /**
     * @description
     * Logs user off by deleting Authorization header info from session / local
     * You can optionally show a message
     */
    logOut(): void {
        this.digestSvc.logOut();
        this.loggedIn = false
    }

    clearUserInfo(): void {
        this.loggedIn = false;
        this.router.navigateByUrl('/pa').then(() => {})
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}